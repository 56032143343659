import React, { useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import Container from '@mui/material/Container';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import Box from '@mui/material/Box';

import Typography from 'src/components/ui/Typography';
import BackLink from 'src/components/ui/BackLink';

import useAppRouter from 'src/hooks/useAppRouter';
import { routePath404, routePathHome } from 'src/constants/router';

const PageNotFound: PageComponent = () => {
    const { t } = useTranslation(['common']);
    const router = useAppRouter();
    useEffect(() => {
        if (router.asPath !== routePath404) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            router.push(routePath404);
        }
    }, [router]);

    return (
        <Container>
            <Box mt={8}>
                <Typography variant="h1" paragraph>
                    404.
                </Typography>
                <Typography variant="h2" paragraph>
                    {t('common:general.pageNotFound')}
                </Typography>
            </Box>
            <BackLink href={routePathHome}>{t('common:general.goToHomePage')}</BackLink>
        </Container>
    );
};

export const getStaticProps = async ({ locale }: { locale: string }) => ({
    props: {
        ...(await serverSideTranslations(locale, ['common'])),
        SEOProps: {
            pageTitleTranslation: {
                key: 'common:seo.error404',
            },
        },
    },
});

export default PageNotFound;
