import type { PropsWithChildren } from 'react';
import React from 'react';
import { css } from '@emotion/react';
import Box from '@mui/material/Box';

import type { LinkProps } from 'src/components/ui/Link';
import Link from 'src/components/ui/Link';
import theme from 'src/utils/theme';
import Icon from 'src/components/ui/Icon';
import { styledComponent, styledTag } from 'src/utils/styled';

const linkStyles = css`
    text-decoration: none;
    color: ${theme.palette.text.primary};
    display: inline-flex;
    align-items: center;
    &:hover {
        color: ${theme.palette.primary.main};
    }
`;

const StyledLink = styledComponent((props: LinkProps) => <Link {...props} />)`
    ${linkStyles}
`;

const LinkButton = styledTag('button')`
    ${linkStyles}
    -webkit-appearance: button;
    background-color: transparent;
    border: none;
    font: inherit;
    letter-spacing: inherit;
    padding: 0;
    cursor: pointer;
    &:focus {
        outline: none;
    }
`;

export interface Props {
    href?: string;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const BackLink: React.FC<PropsWithChildren<Props>> = (props) => {
    const { children, href, onClick, ...rest } = props;

    if (href) {
        return (
            <StyledLink href={href} {...rest}>
                <Box mr={0.5}>
                    <Icon size="1.25rem" name="ArrowBack" />
                </Box>
                {children}
            </StyledLink>
        );
    }

    return (
        <LinkButton onClick={onClick} {...rest}>
            <Box mr={0.5}>
                <Icon size="1.25rem" name="ArrowBack" />
            </Box>
            {children}
        </LinkButton>
    );
};

export default BackLink;
